import Swiper from 'swiper/bundle';

/**
 * SCROLL
 */
$(document).ready(function () {
    $(".navbar").on("click", "navbar-brand", function (event) {
        event.preventDefault();
        let id = $(this).attr('href');
        let top = $(id).offset().top;
        $('body,html').animate({scrollTop: top}, 800);
    });
});

/**
 * SLIDER
*/
const swiper = new Swiper(".swiper-plan", {
    loop: false,
    slidesPerView: 1,
    speed: 600,
    autoHeight: true,
    navigation: {
        nextEl: ".button-next",
        prevEl: ".button-prev",
    },
});

new Swiper(".swiper-comfort", {
    loop: false,
    slidesPerView: 1,
    speed: 600,
    autoplay: true,
    navigation: {
        nextEl: ".button-next-comfort",
        prevEl: ".button-prev-comfort",
    },
});

new Swiper(".swiper-apartment", {
    loop: false,
    slidesPerView: 1,
    speed: 600,
    autoplay: true,
    navigation: {
        nextEl: ".button-next-apartment",
        prevEl: ".button-prev-apartment",
    },
});

new Swiper(".swiper-tech", {
    loop: false,
    slidesPerView: 1,
    speed: 600,
    autoplay: true,
    navigation: {
        nextEl: ".button-next-tech",
        prevEl: ".button-prev-tech",
    },
});

new Swiper(".swiper-order", {
    loop: false,
    slidesPerView: 1,
    speed: 600,
    autoplay: true,
    navigation: {
        nextEl: ".button-next-order",
        prevEl: ".button-prev-order",
    },
});

/**
 * Yandex map
 */
if (document.getElementById('map')) {
    ymaps.ready(function () {
        const myMap = new ymaps.Map("map", {
                center: [47.10693271894984,51.88314332806395],
                zoom: 16
            }, {
                searchControlProvider: "yandex#search"
            }),
            myPlacemark = new ymaps.Placemark([47.10839407430462,51.88026799999997], {
                //
            },{
                preset: 'islands#redDotIcon'
            });
        myMap.geoObjects.add(myPlacemark);
        myMap.behaviors.disable('scrollZoom');
    });
}

/**
 * Video play, pause
 */
$('#playPause').click(function () {
    const video = $("#video");
    const playImg = $("#play-img");
    const pauseImg = $("#pause-img");

    video.css("opacity", "1");

    const mediaVideo = video.get(0);
    if (mediaVideo.paused) {
        mediaVideo.play();
        playImg.css("display", "none");
        pauseImg.css("display", "block");
    } else {
        mediaVideo.pause();
        playImg.css("display", "block");
        pauseImg.css("display", "none");
    }
});

/**
 * Right absolute images position
 */
/*
function imgPosition() {
    const playBtnLeft = $("#playPause").offset().left;
    const imgWidth = 350;

    let position = playBtnLeft - imgWidth;

    $( ".comfort-bckg" ).css({"left": position, "opacity": 1});
    $( ".order-bckg" ).css({"left": position, "opacity": 1});
}

imgPosition();

$( window ).resize(function() {
    setTimeout(imgPosition, 50);
});

*/